<template>
  <div>
    <s-text :readonly="readonly" label="Circuitos" @click="dialogCircuit = true" v-model="textDescription" />

    <v-dialog width="80%" v-model="dialogCircuit" v-if="dialogCircuit" transition="dialog-bottom-transition">
      <v-circuit-list style="overflow: hidden !important" @dblClick="dblClick($event)"></v-circuit-list>
    </v-dialog>
  </div>
</template>

<script>
import VCircuitList from "@/views/InformationTechnology/Configuration/Circuit/CircuitConfig.vue";
export default {
  components: { VCircuitList },
  props: {
    value: null,
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      article: null,
      textDescription: "",
      dialogCircuit: false,
    };
  },
  methods: {
    dblClick(item) {
      this.article = item;
      this.$emit("input", item);
      this.textDescription = item.ProviderName;
      this.dialogCircuit = false;
    },
  },
  created() {
    this.article = this.value;
    if (this.article != null) {
      this.textDescription = this.article.ProviderName;
    }
  },
  created() {},
  watch: {
    value() {
      this.textDescription = this.value.OrdDocumentNumber;
    },
  },
};
</script>
