<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="7">
        <s-crud
          edit
          :filter="filter"
          searchInput
          :config="config"
          add
          remove
          title="Tickets"
          @save="save($event)"
        >
          <template scope="props">
            <v-container>
              <v-row justify="center">
                <v-col cols="5" lg="5" class="s-col-form">
                  <s-select-area
                    label="Área"
                    :text="props.item.AreName"
                    v-model="props.item.AreID"
                    full
                  />
                </v-col>
                <v-col cols="5" lg="5" class="s-col-form">
                  <s-select-definition
                    :def="1179"
                    label="Estado"
                    v-model="props.item.LcsStatus"
                  />
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="10" lg="10" class="s-col-form">
                  <s-text
                    label="Descripcion breve"
                    v-model="props.item.text"
                  ></s-text>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col lg="10" cols="10" class="s-col-form">
                  <s-textarea
                    solo
                    name="input-7-4"
                    label="Descripcion"
                  ></s-textarea>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col lg="10" cols="10" class="s-col-form">
                  <s-load @clear="clear()" label="Adjunto" v-model="files" />
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:ExtStatus="{ row }">
            <v-chip x-small :color="row.ExtStatus == 1 ? 'success' : 'error'">{{
              row.ExtStatus == 1 ? "Activo" : "Baja"
            }}</v-chip>
          </template>
        </s-crud>
      </v-col>
      <v-col cols="12" lg="5">
        <v-card class="mx-auto">
          <v-breadcrumbs :items="itemsbreak">
            <template v-slot:divider>
              <v-icon>mdi-forward</v-icon>
            </template>
          </v-breadcrumbs>
          <v-card-title>
            <v-icon large left> mdi-twitter </v-icon>
            <span class="text-h6 font-weight-light">Twitter</span>
          </v-card-title>

          <v-card-text class="text-h7 font-weight-bold">
            "Turns out semicolon-less style is easier and safer in TS because
            most gotcha edge cases are type invalid as well."
          </v-card-text>
          <v-card-actions>
            <v-list-item class="grow">
              <v-list-item-avatar color="grey darken-3">
                <v-img
                  class="elevation-6"
                  alt=""
                  src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>Kenly Flores</v-list-item-title>
                <span class="text--disabled">Python Developer</span>
              </v-list-item-content>
            </v-list-item>
          </v-card-actions>
          <hr
            role="separator"
            aria-orientation="horizontal"
            class="mt-5 mb-3 v-divider theme--light"
          />
          <v-card-actions
            class="d-flex justify-space-between align-center flex-wrap"
          >
            <small class="text--disavled">Registered 3 mins ago</small>
            <div>
              <v-btn color="orange" text> Share </v-btn>

              <v-btn color="orange" text> Explore </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import _service from "@/services/InformationTechnology/LicenseService";
import SCircuit from "@/views/InformationTechnology/Configuration/Circuit/CircuitView";
export default {
  components: { SCircuit },

  data() {
    return {
      filter: {},
      config: {
        model: {
          LcsID: "ID",
          LcsDateBegin: "date",
          LcsDateEnd: "date",
          LcsStatus: "",
        },
        headers: [
          { text: "Incidencia", value: "Text" },
          { text: "Estado", value: "LcsStatus", width: 150 },
        ],
        service: _service,
      },
      items: [],
      license: {},
      itemsbreak: [
        {
          text: "Dashboard",
          disabled: false,
          href: "breadcrumbs_dashboard",
        },
        {
          text: "Link 1",
          disabled: false,
          href: "breadcrumbs_link_1",
        },
        {
          text: "Link 2",
          disabled: true,
          href: "breadcrumbs_link_2",
        },
      ],
    };
  },
  created() {},
  methods: {
    save(item) {
      alert("emitiendo");
      this.license = item;
      this.$refs.childAcquisition.save(); //evento del componente
    },
  },
};
</script>
