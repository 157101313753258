<template>
  <div>
    <v-row>
      <v-col
        ><s-crud
          searchInput
          @doubleClick="dobleClick"
          title="Circuitos Creados"
          :config="config"
        >
        </s-crud
      ></v-col>
    </v-row>
  </div>
</template>

<script>
import _sCircuit from "@/services/Security/ApprovalCircuitService";
export default {
  components: {},
  props: {},

  data: () => ({
      filter: {
        SecStatus: 1,
      },
    config: {
      model: {
        DocEntry: "ID",
        PcsInvoiceDate: "date",
      },
      service: _sCircuit,
      headers: [
        { text: "N° Orden", value: "OrdDocumentNumber", sortable: false },
        { text: "Proveedor", value: "ProviderName", sortable: false },
        { text: "Factura", value: "PcsInvoice", sortable: false },
        { text: "F.Factura", value: "PcsInvoiceDate", sortable: false },
      ],
    },
  }),
  methods: {
    dobleClick(item) {
      this.$emit("dblClick", item);
    },
  },
  created() {},
};
</script>
